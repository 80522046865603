<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>{{ getCommonText('budget') }}</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                :header="header"
                :isParentGuExist="isParentGuExist"
                :isHeadGu="isHeadGu"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadBatchReports="downloadBatchReports"
                @downloadBatchReportsPaid="downloadBatchReportsPaid"
            ></forms-download-reprt>
        </div>
        <div class="filter-container">
            <div class="left-content">
                <budget-header v-show="categoryMode" ref="budgetHeader" :form="form" @chgData="changeHeader" :yearReq="true" :openDisabled="openDisabled" @setProgress="setProgress"></budget-header>
                <p v-if="!categoryMode" class="title">{{ getCommonText('decoding') }}. {{ currCategory[`name_${this.lng}`] }}</p>
            </div>
            <div class="right-content">
                <div class="filter-actions">
                    <template v-if="categoryMode">
                        <c-budget-forms-copy-data 
                            v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                            :budgetForm="budgetForm"
                            :header="header"
                            :isLoad="isLoad"
                            :total="total"
                            @keyPress="keyPress"
                        />
                    </template>
                    <template v-else>
                        <b-button variant="light" @click="resetModal">
                           <i class="icon icon-keyboard icon-rotate-180"></i> {{ getCommonText('back_to_list') }}
                        </b-button>
                        <b-button :style="{ 'minWidth': '110px' }" v-if="variantAttribute" variant="primary" @click="$refs.formTemplate.addItem()">
                            <i class="icon icon-plus-circle"></i>{{ getCommonText('add') }}
                        </b-button>
                        <b-button
                            :style="{ 'minWidth': '110px' }" 
                            :disabled="!dataChanged || goodsAreSaving"
                            variant="success"
                            @click="prepareForSave">
                            {{ getCommonText('save') }}
                        </b-button>
                    </template>
                </div>
            </div>
        </div>

        <breadcrumbs-filter v-show="categoryMode" :header="{...header, formName: this.formName, spfName: this.spfName}" :data-type-filter="dataTypeFilter"
                            :variant-name="variantName" @openFilterByRef="openFilterByRef"/>

        <b-progress
                variant="success"
                v-show="progress < 100 && progress > 0"
                height="10px"
                :value="progress"
                striped
                animated
        ></b-progress>

        <div v-show="categoryMode" class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header="true"
                no-border-collapse
            >
             <template #head(selection)>
                    <div class="text-center">
                        <b-form-checkbox 
                            v-model="selectAll" 
                            @change="e => setIsAllDelete(e)"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #head(more)>
                    <div class="text-center">
                        <i 
                        :title="getCommonText('del_selected')" 
                        class="icon icon-close table-all-remove"
                        @click="deleteItem(`${selectAll ? getCommonText('all_cats') : getCommonText('selected_cats')}`)"/>
                    </div>
                </template>
                
                <template #top-row="data">
                    <td></td>
                    <td class="td-numbering"></td>
                    <td class="td-numbering text-center table-success">1</td>
                    <td class="td-numbering text-center table-danger">2</td>
                    <td class="td-numbering text-center table-info">3</td>
                    <td class="td-numbering text-center table-primary">4</td>
                    <td class="td-numbering text-center table-warning">5</td>
                    <td class="td-numbering text-center table-success">6</td>
                    <td class="td-numbering text-center table-danger">7</td>
                    <td class="td-numbering text-center table-info">8</td>
                    <td class="td-numbering"></td>
                </template>

                <template #cell(selection)="data">
                    <b-form-checkbox 
                        v-model="data.item.itemToDelete"
                        @input="e => { if (!e) selectAll = false; }"
                        :value="true"
                        :unchecked-value="false" />
                </template>
                <template #cell(action)="data">
                    <button class="table-plus" 
                    v-b-modal.goods-modal @click="setCurrCatName(data.item)">
                        <i class="icon icon-plus-circle"/>
                    </button>
                </template>
                <template #cell(name_ru)="data">
                    <div>
                        {{ data.item[`name_${lng}`] }}
                    </div>
                </template>
                <template #cell(fact_cost)="data">
                    <div class="text-right">{{ String(data.value).replace('.', ',') }}</div>
                </template>
                <template #cell(area)="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(months)="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(volume)="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(price)="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(total)="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(files)="data">
                    <span
                        class="blue pointer underline"
                        @click="onAllFilesClick(data.item)"
                    >({{data.item.files ? data.item.files.length : 0}})</span>
                </template>
                <template #cell(more)="data">
                    <i class="icon icon-clear table-remove" v-if="variantAttribute" :disabled="deletingCat || data.item.total === 0" @click="deleteItem(`${getCommonText('curr_cat')}`, data.item, data.index, true)"></i>
                </template>

                <template #bottom-row="data">
                    <td class="text-right" colspan="8">{{ getCommonText('total') }}</td>
                    <td class="text-right">{{ $n(total) }}</td>
                    <td colspan="2"></td>
                </template>
            </b-table>
        </div>
        <template v-if="!categoryMode">
            <b-overlay :show="templateTableOverlay" rounded="sm">
                <form-template6
                    ref="formTemplate"
                    :goodsData="goodsData"
                    :initialGoodsData="initialGoodsData"
                    :ensTruDict="ensTruDict"
                    :variantAttribute="variantAttribute"
                    :currCategory="currCategory"
                    :lang="lang"
                    :ifAllFieldsFilled="ifAllFieldsFilled"
                    :deleteGood="deleteGood"
                    :getUnitName="getUnitName"
                    :ensTruNamePrt="ensTruNamePrt"
                    :ensTruName="ensTruName"
                    :currUnits="currUnits"
                    :descIsLoading="descIsLoading"
                    :parNameLoading="parNameLoading"
                    :allFiles="allFiles"
                    :formCode="form.code"
                    @setTotals="setTotals"
                    @updateDatas="updateDatas"
                    @save="save"
                    @onDataChanged="onDataChanged"
                    @searchName="searchName"
                    @checkNumber="checkNumber"
                    @setIsAllDelete="setIsAllDelete"
                    @deleteSeveralGoods="deleteSeveralGoods"
                    :triggDownloadFile="triggDownloadFile"
                    :showFileUploadModal="showFileUploadModal"
                    :getEnstruByName="getEnstruByName"
                    :loadCurUnits="loadCurUnits"
                    :goodsAreSaving="goodsAreSaving"
                ></form-template6>
                <div class="table-add" v-if="variantAttribute" >
                    <span @click="$refs.formTemplate.addItem()"><i class="icon icon-plus-circle"></i> {{ getCommonText('add') }}</span>
                </div>
            </b-overlay>
        </template>
        <files-updown ref="filesUpdown"
            v-show="categoryMode"
            :header="header"
            :variant-attribute="variantAttribute"
            :load="load"
            :showDeleteLinks="variantAttribute"
            :showDeleteAll="true"
            @getFiles="getFiles"
            @fillFilesList="fillFilesList"
            @restetFilesList="restetFilesList"
            @delelteAttachedFile="delelteAttachedFile"
        ></files-updown>
        <modal-all-files-management
            ref="modalAllFilesManagement"
            :allFiles="allFiles"
            @triggDownloadFile="triggDownloadFile"
            @triggDownloadAllFiles="triggDownloadAllFiles"
            >
        </modal-all-files-management>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
import FormsHandlerMixin from "./mixins/forms-handler-mixin";
import FormTemplate6 from "./components/budget-forms-template6.vue";
import ModalAllFilesManagement from './components/modal-all-files-management.vue';
import CBudgetFormsCopyData from './components/budget-forms-copy-data.vue';
import FormsDownloadReprt from "./components/forms-download-reprt.vue";

export default {
    name: 'Form02_144',
    components: { BudgetHeader, FilesUpdown, BudgetFormsList, FormsDownloadReprt, BreadcrumbsFilter, FormTemplate6, ModalAllFilesManagement, CBudgetFormsCopyData },
    mixins: [FormsHandlerMixin],
    data() {
        return {
            form: {
                code: '02-144',
                name_ru: 'Расчет расходов на приобретение твердого и жидкого топлива для отопления зданий, помещений для государственных учреждений с автономной системой отопления',
                name_kk: 'Дербес жылыту жүйесi бар мемлекеттiк мекемелер үшiн ғимараттарды, үй-жайларды жылыту үшін қатты және сұйық отынды сатып алуға шығыстарын есептеу'
            },
            dataTypeFilter: null,
            variantAttribute: null,
            variantName: null,
            budgetForm: [],
            header: null,
            dict: null,
            files: null,
            load: false,
            openDisabled: false,
            isLoad: false,
        };
    },

    mounted() {
        this.progress = 15;
    },

    methods: {

        deleteItem(msg, row = false, index = false) {
            if (!this.variantAttribute || this.isLoad 
                || (!row && this.budgetForm.findIndex(itm => itm.itemToDelete) === -1)) return;
            this.currCategory = row;
            this.$bvModal.msgBoxConfirm(
                this.getCommonText('del_with_decodes', {msg: msg}),
                {
                    title: this.getCommonText('confirm'),
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: this.getCommonText('yes'),
                    cancelTitle: this.getCommonText('cancel'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(async value => {
                    if (value) {
                        if (row) {
                            this.itemUpdate(row);
                        } else {
                            const existingRowsToDel = this.budgetForm.filter(item => item.id >= 0 && item.itemToDelete);
                            if (!existingRowsToDel.length) { 
                                this.selectAll = false;
                            } else {
                                await this.deleteSeveralCats(existingRowsToDel);
                            }
                        };
                    };
                })
                .catch(error => {
                    this.makeToast('danger', this.getCommonText('on_del'), error.toString());
                });
        }, // очистить значения строки

        getFiles(data) {
            this.files = data;
        },

        getRowKey(row, keys) {
            let key = '';
            for (const k of keys) {
                key = key + this.padLeadingZeros(row[k], 3) + '.';
            }
            return key;
        }, // преобразует значения выбранных полей в код

        input12(item, field, value) {
            if (parseInt(value) > 12) {
                this.$set(item, field, 0);
                this.makeToast('danger', this.getErrText('warning'), this.getErrText('not_more_12'));
            } else {
                this.$set(item, field, value);
            }
        }, // проверка value>12

        inputFixed(item, field, value, digit) {
            this.$set(item, field, parseFloat(parseFloat(value).toFixed(digit)));
        }, // форматирует введенное значение до digit цифр после запятой

        async itemUpdate(row) {
            await this.loadGoodsData(row);
            process.nextTick(() => {
                this.deleteGood(this.goodsData, true, true);
                this.$set(row, 'fact_cost', 0);
                this.$set(row, 'area', 0);
                this.$set(row, 'months', 0);
                this.$set(row, 'volume', 0);
                this.$set(row, 'price', 0);
                this.$set(row, 'total', 0);
                this.$set(row, 'files', []);
                this.$set(row, 'itemToDelete', false);
                // this.saveTotal();
            });
        },

        keyPress: function (event, pattern) {
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadCategoryData() {
            this.load = true;
            const newBudgetForm = [];
            try {
                const response = await fetch('/api-py/get-budget-request-form-cat/' + JSON.stringify(this.header));
                    if (response.status === 200) {
                        const values = await response.json();
                        this.catsSums = values;
                        this.dict.forEach((row, i) => {
                            if (this.catsSums.length) {
                                const curCatSum = this.catsSums.find(item => item.fuel === row.code);
                                if (curCatSum) {
                                    this.$set(row, 'fact_cost', parseFloat(parseFloat(curCatSum.sum_fact_cost).toFixed(5)));
                                    this.$set(row, 'area', parseFloat(parseFloat(curCatSum.avg_area).toFixed(2)));
                                    this.$set(row, 'months', parseFloat(parseFloat(curCatSum.avg_months).toFixed(2)));
                                    this.$set(row, 'volume', parseFloat(parseFloat(curCatSum.sum_amount).toFixed(2)));
                                    this.$set(row, 'price', parseFloat(parseFloat(curCatSum.avg_price_unit).toFixed(2)));
                                    this.$set(row, 'total', parseFloat(parseFloat(curCatSum.sum_total_price).toFixed(3)));
                                    this.$set(row, 'files', this.getUnicFilesArr(curCatSum));
                                } else {
                                    this.resetForm(row);
                                }
                            } else {
                                    this.resetForm(row);
                                }
                            this.$set(row, 'itemToDelete', false);
                            newBudgetForm.push(row);
                        });
                        this.budgetForm = newBudgetForm.sort((a, b) => a.code - b.code)
                    } else {
                        this.makeToast('danger', `${this.getErrText('bad_request')} loadCategoryData`, `Error code: ${response.status}`);
                    }
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadCategoryData`, error.toString());
            }
            this.load = false;
        },

        resetForm(row) {
            this.$set(row, 'fact_cost', 0);
            this.$set(row, 'area', 0);
            this.$set(row, 'months', 0);
            this.$set(row, 'volume', 0);
            this.$set(row, 'price', 0);
            this.$set(row, 'total', 0);
            this.$set(row, 'files', []);
        },

        async loadDict() {
            try {
                const response = await fetch('/api-py/dictionary/fuels/');
                if (response.status === 200) {
                    this.dict = await response.json();
                    this.dict.forEach((row, i) => {
                        this.$set(row, 'id', i);
                        this.$set(row, 'id_dict', row.id);
                    });
                }
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadDict`, error.toString());
            }
        },

        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) {
                s = '0' + s;
            }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера

        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'is-hidden';
            }
        }, // задает класс 'is-hidden' заданной строке

        prepareData(val) {
            this.$set(val, 'good_type', val.fuel);
            this.$set(val, 'amount', parseFloat((val.fact_cost * val.area * val.months).toFixed(2)));
            this.$set(val, 'total', parseFloat((val.amount * val.price / 1000).toFixed(3)));

            this.setPassVals(val);
        }, // заполнение полей при загрузке расшиыровок

        onDataChanged(item) {
            this.$set(item, "rowToSave", true)
            this.dataChanged = true;
            this.setPassVals(item);
        }, // переключения тригера при изменении данных товаров

        setPassVals(item) {
            this.$set(item, "factcostPassVal", !(!item.fact_cost || item.fact_cost <= 0));
            this.$set(item, "areaPassVal", !(!item.area || item.area <= 0));
            this.$set(item, "monthsPassVal", !(!item.months || item.months <= 0));
            this.$set(item, "pricePassVal", !(!item.price || item.price <= 0));
        },  // валидация полей расшифровок

        ifAllFieldsFilled() {
            const prevNewItemIdx = this.goodsData.findIndex(item => item.isNew === true);
            if (prevNewItemIdx !== -1) {
                const prevNewItem = this.goodsData[prevNewItemIdx];
                if (!prevNewItem.enstru_code) {
                    this.makeToast('warning', this.getCommonText('attention'), this.getCommonText('not_selected'));
                    return false;
                };
            }
            if (this.goodsData.findIndex(item => item.factcostPassVal === false) !== -1) {
                this.makeToast('warning', this.getCommonText('attention'), this.getDecText('fuel_conspsh_more0'));
                return false;
            }
            if (this.goodsData.findIndex(item => item.areaPassVal === false) !== -1) {
                this.makeToast('warning', this.getCommonText('attention'), this.getDecText('area_more0'));
                return false;
            }
            if (this.goodsData.findIndex(item => item.monthsPassVal === false) !== -1) {
                this.makeToast('warning', this.getCommonText('attention'), this.getDecText('duration_more0'));
                return false;
            }
            if (this.goodsData.findIndex(item => item.pricePassVal === false) !== -1) {
                this.makeToast('warning', this.getCommonText('attention'), this.getDecText('avg_price_more0'));
                return false;
            }

            if (prevNewItemIdx !== -1) this.$set(this.goodsData[prevNewItemIdx], 'isNew', false);
            return true
        }, // проверка введенных данных расшифровок перед сохранением

        fillDataForSave(item, row) {
            this.$set(item, 'fuel', this.currCategory.code);
            this.$set(item, 'fact_cost', parseFloat(row.fact_cost));
            this.$set(item, 'area', parseFloat(row.area));
            this.$set(item, 'months', parseFloat(row.months));
            this.$set(item, 'price', parseFloat(row.price));
        }, // заполнение полей Расшифровок перед сохранением

        getCodeFieldName() {
            return 'code';
        }, // при необходимости заменить в родителе

        // пересчет тотала при удалении нескольких записей
        calcTotalBeforeDel() {
            return this.budgetForm.length ? this.budgetForm.reduce((acc, row) => row.itemToDelete ? acc : acc + row.total, 0) : 0;
        },
    },

    computed: {
        total() {
            let sum = 0;
            for (const row of this.budgetForm) {
                sum += parseFloat(row.total);
            }
            return Math.ceil(sum);
        },

        getFormText() {
            return this.setFormText('form_02_144.');
        },

        tableFields() {
            if (this.$i18n.locale) {
                return [
                    {
                        key: 'selection',
                        label: ' '
                    },
                    {
                        key: 'action',
                        label: ' '
                    },
                    {
                        key: 'name_ru',
                        label: this.getFormText('fuel_type')
                    },
                    {
                        key: 'fact_cost',
                        label: this.getFormText('annual_fuel_expense')
                    },
                    {
                        key: 'area',
                        label: this.getFormText('heated_area')
                    },
                    {
                        key: 'months',
                        label: this.getFormText('heating_duration')
                    },
                    {
                        key: 'volume',
                        label: this.getFormText('fuel_volume')
                    },
                    {
                        key: 'price',
                        label: this.getFormText('fuel_cost')
                    },
                    {
                        key: 'total',
                        label: this.getFormText('total_cost')
                    },
                    {
                        key: 'files',
                        label: this.getCommonText('files')
                    },
                    {
                        key: 'more',
                        label: ''
                    }
                ];
            };
            return [];
        }
    }
};
</script>

<style scoped>
.table-plus {
    border: none;
}
</style>